import i18n from '@/plugins/i18n'

const locale = localStorage.getItem('localization') || 'en'

export const MENU_LINKS: Menu = {
  root: [
    { icon: 'fa fa-building', text: i18n.t('menuItems.organizations', locale).toString(), link: '/organizations' },
    { icon: 'fa fa-users', text: i18n.t('menuItems.users', locale).toString(), link: '/users' },
    { icon: 'far fa-address-book', text: i18n.t('menuItems.usersLogs', locale).toString(), link: '/users-logs' },
    { icon: 'fa fa-user-shield', text: i18n.t('menuItems.administrator', locale).toString(), link: '/administrator' },
    { icon: 'fa fa-building', text: i18n.t('menuItems.organizationSummary', locale).toString(), link: '/organization-summary' },
    { icon: 'fa fa-envelope', text: i18n.t('menuItems.smsLogs', locale).toString(), link: '/sms-logs' }
  ],
  admin: [
    { icon: 'fa fa-tachometer-alt', text: i18n.t('menuItems.dashboards', locale).toString(), link: '/dashboards' },
    { icon: 'fas fa-map-marked-alt', text: i18n.t('menuItems.location', locale).toString(), link: '/locations' },
    { icon: 'fas fa-map-signs', text: i18n.t('menuItems.geofences', locale).toString(), link: '/geofences' },
    { icon: 'fa fa-chart-line', text: i18n.t('menuItems.reports', locale).toString(), link: '/reports' },
    { icon: 'fa fa-bell', text: i18n.t('menuItems.notifications', locale).toString(), link: '/notifications' },
    { icon: 'fa fa-calendar-alt', text: i18n.t('menuItems.scheduledReports', locale).toString(), link: '/scheduled-reports' },
    { icon: 'fa fa-users', text: i18n.t('menuItems.users', locale).toString(), link: '/users' },
    { icon: 'fa fa-cogs', text: i18n.t('menuItems.settings', locale).toString(), link: '/settings' },
    { icon: 'far fa-address-book', text: i18n.t('menuItems.usersLogs', locale).toString(), link: '/users-logs' },
    { icon: 'fa fa-building', text: i18n.t('menuItems.organizationSummary', locale).toString(), link: '/organization-summary' },
    { icon: 'fa fa-envelope', text: i18n.t('menuItems.smsLogs', locale).toString(), link: '/sms-logs' }
  ],
  manager: [
    { icon: 'fa fa-tachometer-alt', text: i18n.t('menuItems.dashboards', locale).toString(), link: '/dashboards' },
    { icon: 'fas fa-map-marked-alt', text: i18n.t('menuItems.location', locale).toString(), link: '/locations' },
    { icon: 'fa fa-chart-line', text: i18n.t('menuItems.reports', locale).toString(), link: '/reports' },
    { icon: 'fa fa-bell', text: i18n.t('menuItems.notifications', locale).toString(), link: '/notifications' },
    { icon: 'fa fa-calendar-alt', text: i18n.t('menuItems.scheduledReports', locale).toString(), link: '/scheduled-reports' },
    { icon: 'fa fa-users', text: i18n.t('menuItems.users', locale).toString(), link: '/users' }
  ],
  operator: [
    { icon: 'fa fa-tachometer-alt', text: i18n.t('menuItems.dashboards', locale).toString(), link: '/dashboards' },
    { icon: 'fas fa-map-marked-alt', text: i18n.t('menuItems.location', locale).toString(), link: '/locations' },
    { icon: 'fa fa-chart-line', text: i18n.t('menuItems.reports', locale).toString(), link: '/reports' }
  ]
}

interface Menu {
  [key: string]: MenuItem[]
}

export interface MenuItem {
  icon: string
  text: string
  link: string
}
