import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import conf from '../../../../../package.json'
import { MenuItem, MENU_LINKS } from './constants'
import { UserInfo } from '@/App/Services/interfaces/user.interface'

const sharedStore = namespace('Shared')

@Component
export default class Drawer extends Vue {
  public version = conf.version

  @sharedStore.State
  public isDrawerOpen: boolean

  @sharedStore.State
  public userInfo: UserInfo

  public menuItems: MenuItem[] = []

  private mounted(): void {
    this.menuItems = [...MENU_LINKS[this.userInfo.role]]

    if (this.userInfo.role === 'admin' && this.userInfo.isUseDeviceManagementModule) {
      this.menuItems.push({ icon: 'fas fa-microchip', text: this.$t('menuItems.deviceManagement').toString(), link: '/device-management' })
      this.menuItems.push({ icon: 'fa fa-clone', text: this.$t('menuItems.dashboardTemplates').toString(), link: '/dashboards-templates' })
      this.menuItems.push({ icon: 'fa fa-signal', text: this.$t('menuItems.serviceIntervals').toString(), link: '/service-intervals' })
    }
  }
}
