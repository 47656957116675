import { Component, Mixins } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { Validator } from '@/App/Mixins'
import { Notification } from '@/store/interfaces/shared.interfaces'
import { UserService } from '@/App/Services/User.service'

const sharedStore = namespace('Shared')

@Component
export default class UserSettingsDialog extends Mixins(Validator) {
  $refs: {
    form: HTMLFormElement
  }

  @sharedStore.Mutation
  private readonly setNotification: (notification: Notification) => void

  public isOpen = false
  public isLoading = false
  public password = ''
  public confirmPassword = ''

  public async update(): Promise<void> {
    try {
      this.isLoading = true

      await UserService.selfPasswordUpdate(this.password)

      this.setNotification({ text: this.$t('components.navigationPage.appMenu.userSettingsDialog.notificationText').toString() })

      this.password = ''
      this.isOpen = false
    } catch {} finally {
      this.isLoading = false
    }
  }

  public isPasswordSimilar(): boolean | string {
    return this.password === this.confirmPassword || this.$t('components.navigationPage.appMenu.userSettingsDialog.passwordIsDifferentText').toString()
  }

  get isUpdateBtnDisabled(): boolean {
    return this.isLoading || !this.password || (this.password !== this.confirmPassword)
  }
}
