import { VuexModule, Module, Mutation } from 'vuex-module-decorators'
import { ScheduleReport as IScheduleReport, ScheduleReportUpdate } from '@/App/Services/interfaces/scheduleReport.interface'

@Module({ namespaced: true, name: 'ScheduleReport' })
class ScheduleReport extends VuexModule {
  public scheduleReports: IScheduleReport[] = []

  @Mutation
  public setScheduleReports(reports: IScheduleReport[]): void {
    this.scheduleReports = reports
  }

  @Mutation
  public addScheduleReport(reports: IScheduleReport): void {
    this.scheduleReports.push(reports)
  }

  @Mutation
  public updateScheduleReport(report: ScheduleReportUpdate): void {
    this.scheduleReports = this.scheduleReports.map(r => {
      if (r._id === report._id) {
        return { ...r, ...report }
      } else {
        return r
      }
    })
  }

  @Mutation
  public deleteScheduleReport(id: string): void {
    this.scheduleReports = this.scheduleReports.filter(r => r._id !== id)
  }
}

export default ScheduleReport
