import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { default as dayjs } from 'dayjs'
import { HTTP } from '@/App/Utils/axios'
import { TriggeredNotificationService } from '@/App/Services/TriggeredNotification.service'
import TriggeredNotificationsDialog from '@/App/Components/TriggeredNotificationsDialog/Triggered-Notifications-Dialog.vue'
import UserSettingsDialog from './UserSettingsDialog/User-Settings-Dialog.vue'
import MasterTokenDialog from './MasterTokenDialog/Master-Token-Dialog.vue'
import SupportForm from './SupportForm/SupportForm.vue'
import { UserInfo } from '@/App/Services/interfaces/user.interface'

const sharedStore = namespace('Shared')

@Component({
  components: { TriggeredNotificationsDialog, SupportForm, UserSettingsDialog, MasterTokenDialog }
})
export default class Navigation extends Vue {
  $refs: {
    triggeredNotificationsDialog: HTMLFormElement,
    supportForm: HTMLFormElement
  }

  @sharedStore.Mutation
  private readonly setUserInfo: (user: UserInfo | null) => void

  @sharedStore.Mutation
  public toggleNavigationDrawer: () => void

  @sharedStore.Mutation
  private setTriggeredNotificationsCount: (count: number) => void

  @sharedStore.Mutation
  private toggleSearchBar: () => void

  @sharedStore.Mutation
  private updateSearch: (search: string) => void

  @sharedStore.State
  public userInfo: UserInfo

  @sharedStore.State
  public triggeredNotificationCount: number

  @sharedStore.State
  public isSearchBarShow: boolean

  public digitalClock = ''
  private clockTimeout: number
  public currentDate = dayjs().format('MM.DD.YYYY')

  public async logout(): Promise<void> {
    localStorage.removeItem('user')

    await HTTP.post('/users/logout')

    this.setUserInfo(null)
    this.$emit('onLogout')

    this.$router.push('/login')
  }

  private async mounted(): Promise<void> {
    try {
      const { items } = await TriggeredNotificationService.fetch({ isViewed: false })

      this.setTriggeredNotificationsCount(items.length)

      this.clockTimeout = setInterval(() => {
        this.digitalClock = dayjs().format('HH:mm:ss')
      }, 1000)
    } catch {}
  }

  public openTriggeredNotificationsDialog() {
    this.$refs.triggeredNotificationsDialog.open()
  }

  public openSupportForm() {
    this.$refs.supportForm.open()
  }

  private destroyed(): void {
    clearInterval(this.clockTimeout)
  }

  get isSearchBarVisible(): boolean {
    // @ts-ignore
    return this.$route.meta.isSearchBarVisible
  }

  get isNotificationsBadgeVisible(): boolean {
    return this.triggeredNotificationCount !== 0 && this.userInfo.role !== 'root'
  }

  get isNotificationsBtnVisible(): boolean {
    return this.userInfo.role !== 'root'
  }
}
