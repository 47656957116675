import Vue from 'vue'
import Vuex from 'vuex'
import Shared from './modules/shared.store'
import Organization from './modules/organization.store'
import User from './modules/user.store'
import Dashboard from './modules/dashboard.store'
import Widget from './modules/widget.store'
import Notification from './modules/notification.store'
import ScheduleReport from './modules/scheduleReports.store'
import Admin from './modules/admin.store'
import Device from './modules/device.store'
import DeviceGroup from './modules/deviceGroup.store'
import DeviceManagement from './modules/deviceManagement.store'
import Location from './modules/location.store'
import Geofence from './modules/geofence.store'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    Shared,
    Organization,
    User,
    Dashboard,
    Widget,
    Notification,
    ScheduleReport,
    Admin,
    Device,
    DeviceGroup,
    DeviceManagement,
    Location,
    Geofence
  }
})
