import { VuexModule, Module, Mutation } from 'vuex-module-decorators'
import { DeviceGroupResponse } from '@/App/Services/interfaces/deviceGroup.interface'

@Module({ namespaced: true, name: 'DeviceGroup' })
class DeviceGroup extends VuexModule {
  public groups: DeviceGroupResponse[] = []
  public editingGroup: DeviceGroupResponse = {} as DeviceGroupResponse

  @Mutation
  public setGroups(groups: DeviceGroupResponse[]): void {
    this.groups = groups
  }

  @Mutation
  public addGroup(group: DeviceGroupResponse): void {
    this.groups.push(group)
  }

  @Mutation
  public destroyGroup(_id: string): void {
    this.groups = this.groups.filter(g => g._id !== _id)
  }

  @Mutation
  public updateGroup(group: DeviceGroupResponse): void {
    this.groups = this.groups.map(d => {
      if (d._id === group._id) {
        return group
      } else {
        return d
      }
    })
  }

  @Mutation
  public editGroup(group: DeviceGroupResponse): void {
    this.editingGroup = group
  }
}

export default DeviceGroup
