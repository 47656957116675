import { Component, Vue, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { TriggeredNotification as Notification } from '@/store/interfaces/shared.interfaces'
import { TriggeredNotificationService } from '@/App/Services/TriggeredNotification.service'

const sharedStore = namespace('Shared')

@Component
export default class TriggeredNotification extends Vue {
  public isOpen = false
  public sound = new Audio(require('@/assets/sounds/Hazer__klankbeeld__fire-alarm.wav'))
  private soundTimeout: number
  @sharedStore.State public triggeredNotification: Notification

  // TODO maybe add notification message for his action
  public async onMarkAsViewed(id: string) {
    try {
      await TriggeredNotificationService.update(id)

      this.sound.pause()
      this.isOpen = false
    } catch (e) {
      console.log(e)
    }
  }

  @Watch('triggeredNotification')
  notificationChanged() {
    if (this.soundTimeout) {
      clearTimeout(this.soundTimeout)
      this.sound.pause()
    }

    this.isOpen = true

    this.sound.loop = true
    this.sound.play()

    this.soundTimeout = setTimeout(() => this.sound.pause(), 6e4)
  }
}
