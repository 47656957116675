import { HTTP } from '@/App/Utils/axios'

export class SettingsService {
  static async store(masterToken: string): Promise<void> {
    try {
      await HTTP.post('/settings', { masterToken })
    } catch (e) {
      throw e
    }
  }
}
