import Vue from 'vue'
import dayjs from 'dayjs'
import i18n from '@/plugins/i18n'
import duration, { DurationUnitType } from 'dayjs/plugin/duration'

dayjs.extend(duration)

Vue.filter('date', function (date: number, format = 'MM.DD.YYYY HH:mm:ss') {
  date = +date.toFixed(0)

  if (date.toString().length < 10) {
    return i18n.tc('filters.invalidDateText')
  }

  // Hack for transform unix time in unix timestamp (server can send both the unix and the unix timestamp)
  if (date.toString().length === 10) {
    date = date * 1000
  }

  return dayjs(date).format(format)
})

Vue.filter('round', function (val: number | string, roundingTo = 0) {
  if (typeof val === 'string') {
    val = parseFloat(val).toFixed(roundingTo)
  } else {
    val = val.toFixed(roundingTo)
  }

  return val
})

Vue.filter('duration', function (val: number | string, unit: DurationUnitType = 'second', format = 'HH:mm:ss') {
  return dayjs.duration(+val, unit).format(format)
})
