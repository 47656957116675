import { Component, Mixins } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { Validator } from '@/App/Mixins'
import { Notification } from '@/store/interfaces/shared.interfaces'
import { SettingsService } from '@/App/Services/Settings.service'
import { ValidationResult } from '@/types'

const sharedStore = namespace('Shared')

@Component
export default class MasterTokenDialog extends Mixins(Validator) {
  $refs: {
    form: HTMLFormElement
  }

  @sharedStore.Mutation
  private readonly setNotification: (notification: Notification) => void

  public isOpen = false
  public isLoading = false
  public form = false
  public token = ''

  public async update(): Promise<void> {
    try {
      this.isLoading = true

      await SettingsService.store(this.token)

      this.setNotification({ text: this.$t('components.navigationPage.appMenu.masterTokenDialog.notificationText').toString() })

      this.token = ''
      this.isOpen = false
    } catch {} finally {
      this.isLoading = false
    }
  }
  public masterTokenValidator(val: string): ValidationResult {
    if (val && val && val.length < 64 || val.length > 64) {
      return this.$t('components.navigationPage.appMenu.masterTokenDialog.validatorText').toString()

    }

    return true
  }
}
