import { Component, Watch, Vue } from 'vue-property-decorator'
import TriggeredNotifications from './TriggeredNotifications/Triggered-Notifications.vue'
import NotificationsHistoryTable from './NotificationsHistoryTable/Notifications-History-Table.vue'

@Component({
  components: { TriggeredNotifications, NotificationsHistoryTable }
})
export default class TriggeredNotificationsDialog extends Vue {
  $refs: {
    triggeredNotifications: HTMLFormElement,
    notificationsHistoryTable: HTMLFormElement
  }

  public isOpen = false

  public open(): void {
    this.isOpen = true
  }

  @Watch('isOpen')
  private valueChanged(val: boolean): void {
    if (val) {
      this.$refs.triggeredNotifications.fetchNotifications()
      this.$refs.notificationsHistoryTable.fetchNotifications()
    }
  }
}
