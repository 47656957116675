import { HTTP } from '@/App/Utils/axios'
import { FetchOptions, TriggeredNotificationResponse } from '@/App/Services/interfaces/triggered-notification.interface'

export class TriggeredNotificationService {
  static async fetch(params: FetchOptions): Promise<TriggeredNotificationResponse> {
    try {
      const { data } = await HTTP.get('/triggered-notifications', { params: { ...params } })

      return data
    } catch (e) {
      throw e
    }
  }

  static async update(id: string): Promise<void> {
    try {
      await HTTP.patch(`/triggered-notifications/${id}`)
    } catch (e) {
      throw e
    }
  }
}
