import { Component, Vue } from 'vue-property-decorator'
import FileInput from 'vue-upload-component'

@Component({
	components: { FileInput }
})
export default class FileUpload extends Vue {
  public files: any[] = []
  public errorMessage = ''

  public clear(): void {
		this.files = []
		this.$emit('input', [])
  }

  public inputFilter(newFile: any, _: any, prevent: any): void {
		if (!/\.(jpg|png)$/i.test(newFile.name)) {
			this.errorMessage = 'jpg or png files available'
			return prevent()
		} else {
			this.errorMessage = ''
		}
  }

  public fileSize(size: number): string {
		return (size / 1e+6).toFixed(1) + ' Mb'
  }

  public remove(id: string) {
		this.files = this.files.filter(f => id !== f.id)

		this.filesChanged()
	}

	private filesChanged() {
		const files: string[] = []

		this.files.forEach(f => files.push(f.file))
		this.$emit('input', files)
	}
}
