import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import { Confirm, Notification, TriggeredNotification, ConfirmDialog } from '@/store/interfaces/shared.interfaces'
import { UserInfo } from '@/App/Services/interfaces/user.interface'

@Module({ namespaced: true })
class Shared extends VuexModule {
  public userInfo: UserInfo | null = null
  public notification: Notification = {
    color: '',
    timeout: 3000,
    text: ''
  }
  public triggeredNotificationCount = 0
  public triggeredNotification: TriggeredNotification = {
    _id: '',
    text: ''
  }
  public isDrawerOpen = true
  public confirmDialog: ConfirmDialog
  public globalLoader = false
  public isSearchBarShow = false
  public search = ''

  @Mutation
  public setUserInfo(user: UserInfo): void {
    this.userInfo = user

    localStorage.setItem('user', JSON.stringify(this.userInfo))
  }

  @Mutation
  public setNotification({ color = 'success', timeout = 3000, text }: Notification): void {
    this.notification = {
      color,
      timeout,
      text
    }
  }

  @Mutation
  public setTriggeredNotification(notification: TriggeredNotification): void {
    this.triggeredNotification = notification
  }

  @Mutation
  public setTriggeredNotificationsCount(count: number): void {
    this.triggeredNotificationCount = count
  }

  @Mutation
  public toggleNavigationDrawer(): void {
    this.isDrawerOpen = !this.isDrawerOpen
  }

  @Mutation
  public setConfirmDialog(dialog: ConfirmDialog): void {
    this.confirmDialog = dialog
  }

  @Mutation
  public toggleGlobalLoader(): void {
    this.globalLoader = !this.globalLoader
  }

  @Mutation
  public toggleSearchBar(): void {
    this.isSearchBarShow = !this.isSearchBarShow

    if (!this.isSearchBarShow) {
      this.search = ''
    }
  }

  @Mutation
  public updateSearch(search: string): void {
    this.search = search
  }

  @Action
  public async confirm({ title, text }: Confirm): Promise<boolean> {
    try {
      return await this.confirmDialog.open({ title, text })
    } catch (e) {
      throw e
    }
  }

  get logo(): string {
    // @ts-ignore
    return this.settings.logo
  }
}

export default Shared
