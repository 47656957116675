import { Component, Vue, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { Notification } from '@/store/interfaces/shared.interfaces'

const sharedStore = namespace('Shared')

@Component
export default class ToasterNotification extends Vue {
  public isOpen = false

  @sharedStore.State public notification: Notification

  @Watch('notification', { deep: true })
  notificationChanged() {
    this.isOpen = true
  }
}
