import { NavigationGuardNext, Route } from 'vue-router'
import store from '../../store'
import router from '../'
import { setToken } from '@/App/Utils/axios'
import { UserService } from '@/App/Services/User.service'
import { UserInfo } from '@/App/Services/interfaces/user.interface'

export async function isAuth(to: Route, from: Route, next: NavigationGuardNext) {
  const stateUser: UserInfo = store.state.Shared.userInfo

  const storageUser: string | null = localStorage.getItem('user')

  if (!stateUser && !storageUser) {
    router.push('/login')
  } else if (!stateUser && storageUser) {

    setToken(JSON.parse(storageUser).token)

    const user = await UserService.authByJWTToken()

    store.commit('Shared/setUserInfo', user)

    next()
  } else {
    next()
  }
}
