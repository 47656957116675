import { HTTP } from '@/App/Utils/axios'
import { BaseOrganization, IOrganization } from '@/App/Services/interfaces/organization.interface'

export class OrganizationService {
  static async fetch(): Promise<IOrganization[]> {
    try {
      const { data } = await HTTP.get('/organizations/')

      return data
    } catch (e) {
      throw e
    }
  }

  static async store(organization: BaseOrganization): Promise<IOrganization> {
    try {
      const { data } = await HTTP.post('/organizations/', organization)

      return data
    } catch (e) {
      throw e
    }
  }

  static async update(organizationId: string, organization: Partial<BaseOrganization>): Promise<IOrganization> {
    try {
      const { data } = await HTTP.patch(`/organizations/${organizationId}`, organization)

      return data
    } catch (e) {
      throw e
    }
  }

  static async destroy(id: string): Promise<string> {
    try {
      const { data } = await HTTP.delete(`/organizations/${id}`)

      return data
    } catch (e) {
      throw e
    }
  }

  static async uploadLogo(organizationId: string, logo: string): Promise<string> {
    try {
      const { data } = await HTTP.post(`/organizations/${organizationId}/logo`, { logo })

      return data
    } catch (e) {
      throw e.response
    }
  }
}
