import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { TriggeredNotificationService } from '@/App/Services/TriggeredNotification.service'
import { Notification, TriggeredNotification } from '@/store/interfaces/shared.interfaces'

const sharedStore = namespace('Shared')

@Component
export default class TriggeredNotifications extends Vue {
  @sharedStore.Mutation
  private readonly setNotification: (notification: Notification) => void

  @sharedStore.Mutation
  private readonly setTriggeredNotificationsCount:(count: number) => void

  public isLoading = false
  public loadingItems: string[] = []
  public search = ''
  public notificationsItems: TriggeredNotification[] = []

  public async onMarkAsViewed(id: string): Promise<void> {
    try {
      this.isLoading = true
      this.loadingItems.push(id)

      await TriggeredNotificationService.update(id)

      if (id !== 'all') {
        this.notificationsItems = this.notificationsItems.filter(n => n._id !== id)

        this.setNotification({ text: this.$t('triggeredNotificationsDialog.triggeredNotifications.markAsViewedNotificationText').toString() })
      } else {
        this.notificationsItems = []
        this.setTriggeredNotificationsCount(0)
        this.setNotification({ text: this.$t('triggeredNotificationsDialog.triggeredNotifications.markAllAsViewedNotificationText').toString() })
      }
    } catch {} finally {
      this.isLoading = false
      this.loadingItems = this.loadingItems.filter(i => i !== id)
    }
  }

  public isItemLoading(id: string): boolean {
    return this.loadingItems.includes(id)
  }

  get isBtnDisabled(): boolean {
    return this.notificationsItems.length === 0 || this.isLoading
  }

  public async fetchNotifications(): Promise<void> {
    try {
      this.isLoading = true

      const { items } = await TriggeredNotificationService.fetch({ isViewed: false })

      this.notificationsItems = items
    } catch {} finally {
      this.isLoading = false
    }
  }
}
