import { NavigationGuardNext, Route } from 'vue-router'
import store from '../../store'
import router from '../'
import { UserRole } from '@/types'

export function roleAccess({ path }: Route, from: Route, next: NavigationGuardNext) {
  const role: UserRole = store.state.Shared.userInfo.role

  switch (path) {
    case '/users':
      if (role === 'operator') {
        router.push('/dashboards')
      } else {
        next()
      }
      break
    case '/administrator':
      if (role && role !== 'root') {
        router.push('/dashboards')
      } else {
        next()
      }
      break
    case '/organizations':
      if (role && role !== 'root') {
        router.push('/dashboards')
      } else {
        next()
      }
      break
    case '/organization-summary':
    case '/device-management':
    case '/sms-logs':
    case '/settings':
      if (role && role !== 'root' && role !== 'admin') {
        router.push('/dashboards')
      } else {
        next()
      }
      break
    default:
      next()
  }
}
