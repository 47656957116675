import { VuexModule, Module, Mutation } from 'vuex-module-decorators'
import { UserOnline } from '@/App/Services/interfaces/admin.interface'

@Module({ namespaced: true, name: 'User' })
class AdminStore extends VuexModule {
  public usersOnline: UserOnline[] = []

  @Mutation
  public setUsersOnline(user: UserOnline[]): void {
    this.usersOnline = user
  }

  @Mutation
  public addUserOnline(user: UserOnline): void {
    this.usersOnline.push(user)
  }

  @Mutation
  public removeUserOnline(uid: string): void {
    this.usersOnline = this.usersOnline.filter(u => u.uid !== uid)
  }
}

export default AdminStore
