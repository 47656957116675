import { Component, Vue } from 'vue-property-decorator'

@Component
export class DashboardMenuActions extends Vue {
  public onEdit(): void {
    this.$emit('onEdit')
  }

  public onShare(): void {
    this.$emit('onShare')
  }

  public onRemove(): void {
    this.$emit('onRemove')
  }
}
