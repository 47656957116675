import axios from 'axios'
import i18n from '@/plugins/i18n'
import store from '@/store'
import { BASE_URL } from '@/App/Constants'
// TODO delete it after release
let token = ''
const setToken = (bearerToken: string) => {
  token = bearerToken
}

const baseHTTP = axios.create({
  baseURL: BASE_URL
})

baseHTTP.interceptors.response.use(response => {
  return response
}, e => {
  const error = e.response
  if (error.status === 500) {
    store.commit('Shared/setNotification', { color: 'error', text: i18n.tc('general.hazerCoreError') })
  } else if (error.status === 422) {
    store.commit('Shared/setNotification', { color: 'error', text: error.data.join(',') })
  } else {
    store.commit('Shared/setNotification', { color: 'error', text: error.data.error })
  }

  return Promise.reject(error.response)
})

const FileHTTP = axios.create({
  baseURL: BASE_URL,
  transformRequest: [function (data, headers) {
    headers.Authorization = `Bearer ${token}`

    return data
  }]
})

const HTTP = axios.create({
  baseURL: BASE_URL,
  transformRequest: [function (data, headers) {
    headers['Authorization'] = `Bearer ${token}`

    return JSON.stringify(data)
  }],
  headers: {
    'Content-Type': 'application/json'
  }
})

HTTP.interceptors.response.use(response => {
  return response
}, e => {
  const error = e.response
  switch (error.status) {
    case 403:
      store.commit('Shared/setNotification', { color: 'error', text: error.data.error })
      break
    case 422:
      store.commit('Shared/setNotification', { color: 'error', text: error.data.join(',') })
      break
    default:
      store.commit('Shared/setNotification', { color: 'error', text: i18n.tc('general.hazerCoreError') })
      break
  }

  return Promise.reject(error.response)
})

export { baseHTTP, FileHTTP, HTTP, setToken }
