import { baseHTTP, HTTP } from '@/App/Utils/axios'
import {
  UserInfo,
  IUser,
  Settings,
  UserUpdateRequest, UserStoreRequest
} from '@/App/Services/interfaces/user.interface'

export class UserService {
  static async activate(code: string): Promise<void> {
    try {
      await baseHTTP.post('/users/activate', { code })

      return
    } catch (e) {
      throw e
    }
  }

  static async resetPassword(email: string, login: string): Promise<void> {
    try {
      await baseHTTP.post('/users/reset-password', { email, login })

      return
    } catch (e) {
      throw e.response
    }
  }

  static async selfPasswordUpdate(password: string, code?: string): Promise<void> {
    try {
      if (code) {
        await baseHTTP.patch('/users/password', { password, code })
      } else {
        await HTTP.patch('/users/password', { password })
      }
    } catch (e) {
      throw e
    }
  }

  static async auth(login: string, password: string): Promise<UserInfo> {
    try {
      const { data } = await baseHTTP.post('/users/auth', { login, password })

      return data
    } catch (e) {
      throw e.response
    }
  }

  static async authByJWTToken(): Promise<UserInfo> {
    try {
      const { data } = await HTTP.post('/users/auth')

      return data
    } catch (e) {
      console.log(e)
      throw e.response
    }
  }

  static async fetch(): Promise<IUser[]> {
    try {
      const { data } = await HTTP.get('/users')

      return data
    } catch (e) {
      throw e
    }
  }

  static async store(user: UserStoreRequest): Promise<IUser> {
    try {
      const { data } = await HTTP.post('/users', user)

      return data
    } catch (e) {
      throw e
    }
  }

  static async update(id: string, user: UserUpdateRequest): Promise<IUser> {
    try {
      const { data } = await HTTP.patch(`/users/${id}`, user)

      return data
    } catch (e) {
      throw e
    }
  }

  static async updateSettings(settings: Partial<Settings>): Promise<Settings> {
    try {
      const { data } = await HTTP.patch(`/users/settings`, settings)

      return data
    } catch (e) {
      throw e
    }
  }

  static async destroy(id: string): Promise<string[]> {
    try {
      const { data } = await HTTP.delete(`/users/${id}`)

      return data
    } catch (e) {
      throw e
    }
  }

  static async resendActivationLink(id: string): Promise<void> {
    try {
      await HTTP.post(`/users/${id}/resend-activation-link`)
    } catch (e) {
      throw e
    }
  }
}
