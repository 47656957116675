import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import { IOrganization } from '@/App/Services/interfaces/organization.interface'
import { OrganizationService } from '@/App/Services/Organization.service'

@Module({ namespaced: true })
class OrganizationStore extends VuexModule {
  public organizations: IOrganization[] = []

  @Mutation
  public setOrganizations(organizations: IOrganization[]): void {
    this.organizations = organizations
  }

  @Mutation
  public addOrganization(organization: IOrganization): void {
    this.organizations.push(organization)
  }

  @Mutation
  public updateOrganization(organization: Partial<IOrganization>): void {
    this.organizations = this.organizations.map(o => {
      if (o._id === organization._id) {
        return { ...o, ...organization }
      } else {
        return o
      }
    })
  }

  @Mutation
  public destroyOrganization(id: string): void {
    this.organizations = this.organizations.filter(o => o._id !== id)
  }

  @Action
  public async fetchOrganizations(): Promise<void> {
    try {
      this.context.commit('setOrganizations', await OrganizationService.fetch())
    } catch (e) {
      throw e
    }
  }
}

export default OrganizationStore
