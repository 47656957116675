import { VuexModule, Module, Mutation } from 'vuex-module-decorators'
import { IDashboard } from '@/App/Services/interfaces/dashboard.interface'

@Module({ namespaced: true, name: 'Dashboard' })
class DashboardStore extends VuexModule {
  public dashboards: IDashboard[] = []

  @Mutation
  public setDashboards(dashboards: IDashboard[]): void {
    this.dashboards = dashboards
  }

  @Mutation
  public addDashboard(dashboard: IDashboard): void {
    this.dashboards.push(dashboard)
  }

  @Mutation
  public updateDashboard(dashboard: Partial<IDashboard>): void {
    this.dashboards = this.dashboards.map(d => {
      if (d._id === dashboard._id) {
        return { ...d, ...dashboard }
      } else {
        return d
      }
    })
  }

  @Mutation
  public deleteDashboard(id: string): void {
    this.dashboards = this.dashboards.filter(d => d._id !== id)
  }

  get findDashboardById() {
    return (id: string) => {
      return this.dashboards.find(d => d._id === id)
    }
  }
}

export default DashboardStore
