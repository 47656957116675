import Vue from 'vue'
import 'vue-resize/dist/vue-resize.css'
// @ts-ignore
import VueResize from 'vue-resize'

Vue.use(VueResize)

export interface ResizeEvent {
  width: number
  height: number
}
