import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import Navigation from '@/App/Components/Navigation/AppMenu/Navigation.vue'
import Drawer from '@/App/Components/Navigation/Drawer/Drawer.vue'
import ToasterNotification from '@/App/Components/ToasterNotification/Toaster-Notification.vue'
import TriggeredNotification from '@/App/Components/TriggeredNotification/Triggered-Notification.vue'
import { UserInfo } from '@/App/Services/interfaces/user.interface'

const sharedStore = namespace('Shared')

@Component({
  components: { ToasterNotification, TriggeredNotification, Navigation, Drawer }
})
export default class App extends Vue {
  @sharedStore.State
  private readonly userInfo: UserInfo

  public mounted() {
    const localization = localStorage.getItem('localization')
    if (localization) {
       this.$i18n.locale=localization
    }
  }

  public isTokenValid: false

  get isUserAuth(): boolean {
    return !!this.userInfo
  }
}

