import { VuexModule, Module, Mutation } from 'vuex-module-decorators'
import { StoreGeofence } from '@/App/Services/interfaces/geofence.interface'
import { Geofence } from '@/store/interfaces/geofence.interfaces'

@Module({ namespaced: true })
class GeofenceStore extends VuexModule {
  public geofences: Geofence[] = []
  public canAddPoint = false
  public geofenceForm: StoreGeofence = {
    name: '',
    type: 'circle',
    radius: 20,
    coordinates: [],
    description: '',
    color: '#3e65fe'
  }

  @Mutation
  public setGeofences(geofences: Geofence[]): void {
    this.geofences = geofences
  }

  @Mutation
  public addGeofence(geofence: Geofence): void {
    this.geofences.push(geofence)
  }

  @Mutation
  public updateGeofence(geofence: Partial<Geofence>): void {
    this.geofences = this.geofences.map(g => {
      if (g._id === geofence._id) {
        return { ...g, ...geofence }
      } else {
        return g
      }
    })
  }

  @Mutation
  public removeGeofence(id: string): void {
    this.geofences = this.geofences.filter(g => g._id !== id)
  }

  @Mutation
  public togglePointAdding(): void {
    this.canAddPoint = !this.canAddPoint
  }

  @Mutation
  public updateGeofenceForm(geofence: Partial<StoreGeofence>): void {
    this.geofenceForm = { ...this.geofenceForm, ...geofence }
  }
}

export default GeofenceStore
