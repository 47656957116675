import { VuexModule, Module, Mutation } from 'vuex-module-decorators'
import { Notification } from '@/App/Services/interfaces/notification.interface'
import { NotificationUpdate } from '@/store/interfaces/notification.interface'

@Module({ namespaced: true, name: 'Notification' })
class NotificationStore extends VuexModule {
  public notifications: Notification[] = []

  @Mutation
  public setNotifications(notifications: Notification[]): void {
    this.notifications = notifications
  }

  @Mutation
  public addNotification(notification: Notification): void {
    this.notifications.push(notification)
  }

  @Mutation
  public updateNotification(notification: NotificationUpdate): void {
    this.notifications = this.notifications.map(n => {
      if (n._id === notification._id) {
        return { ...n, ...notification }
      } else {
        return n
      }
    })
  }

  @Mutation
  public deleteNotification(id: string): void {
    this.notifications = this.notifications.filter(n => n._id !== id)
  }
}

export default NotificationStore
