import { VuexModule, Module, Mutation } from 'vuex-module-decorators'
import { UpdateWidget, Widget } from '@/store/interfaces/widget.interface'

@Module({ namespaced: true, name: 'Widget' })
class WidgetStore extends VuexModule {
  public widgets: Widget[] = []

  @Mutation
  public setWidgets(widgets: Widget[]): void {
    this.widgets = widgets
  }

  @Mutation
  public addWidget(widget: Widget): void {
    this.widgets.push(widget)
  }

  @Mutation
  public updateWidget(widget: UpdateWidget): void {
    this.widgets = this.widgets.map(w => {
      if (w._id === widget._id) {
        return { ...w, ...widget }
      } else {
        return w
      }
    })
  }

  @Mutation
  public deleteWidget(id: string): void {
    this.widgets = this.widgets.filter(w => w._id !== id)
  }

  get widgetsByDashboardId() {
    return (id: string) => {
      return this.widgets.filter(w => w.dashboardId === id)
    }
  }

  get widgetByDashboardId() {
    return (dashboardId: string, widgetId: string) => {
      return this.widgets.find(w => w.dashboardId === dashboardId && w._id === widgetId)
    }
  }
}

export default WidgetStore
