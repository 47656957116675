import { VuexModule, Module, Mutation } from 'vuex-module-decorators'
import { IUser } from '@/App/Services/interfaces/user.interface'

@Module({ namespaced: true, name: 'User' })
class User extends VuexModule {
  public users: IUser[] = []

  @Mutation
  public setUsers(users: IUser[]): void {
    this.users = users
  }

  @Mutation
  public addUser(user: IUser): void {
    this.users.push(user)
  }

  @Mutation
  public destroyUser(id: string): void {
    this.users = this.users.filter(u => u._id !== id)
  }

  @Mutation
  public updateUser(user: IUser): void {
    this.users = this.users.map(u => {
      if (u._id === user._id) {
        return user
      } else {
        return u
      }
    })
  }
}

export default User
