import { VuexModule, Module, Mutation } from 'vuex-module-decorators'
import L from 'leaflet'
import { DeviceResponse } from '@/App/Services/interfaces/device.interface'

@Module({ namespaced: true, name: 'Widget' })
class LocationStore extends VuexModule {
  public devices: DeviceResponse[] = []
  public originDevices: DeviceResponse[] = []
  public mapDevices: MapDeviceItem[] = []
  public deviceFollow: FollowDeviceItem | null = null
  public mapCenter: [number, number] = [0, 0]

  @Mutation
  public setDevices(devices: DeviceResponse[]): void {
    this.devices = devices
    this.originDevices = devices
  }

  @Mutation
  public addDevice(device: DeviceResponse): void {
    this.devices.push({ ...device })
    this.originDevices = { ...this.devices }
  }

  @Mutation
  public updateDevice(device: DeviceResponse): void {
    this.devices = this.devices.map(d => {
      if (d._id === device._id) {
        return device
      } else {
        return d
      }
    })
    this.originDevices = [...this.devices]
  }

  @Mutation
  public searchDevice(text: string): void {
    if (text) {
      this.devices = this.originDevices.filter(od => od.name.toLowerCase().includes(text.toLowerCase()))
    } else {
      this.devices = [...this.originDevices]
    }
  }

  @Mutation
  public setFollowDevices(device: FollowDeviceItem): void {
    this.deviceFollow = device
  }

  @Mutation
  public updateDeviceSensor(sensor: UpdateDeviceSensor): void {
    const dIdx = this.devices.findIndex(d => d._id === sensor.deviceId)
    const sIdx = this.devices[dIdx].sensors.findIndex(s => s._id === sensor.sensorId)

    const device = { ...this.devices[dIdx] }
    device.sensors[sIdx] = {
      ...device.sensors[sIdx],
      lastValue: sensor.lastValue,
      lastUpdateTime: sensor.lastUpdateTime
    }
    this.devices = this.devices.map((d, idx) => {
      if (idx === dIdx) {
        return device
      }
      return d
    })

    if (sensor.sensorName === 'position') {
      const idx = this.mapDevices.findIndex(d => d.id === sensor.deviceId && d.positionSensorId === sensor.sensorId)
      const { latitude, longitude, direction } = JSON.parse(sensor.lastValue)
      if (idx !== -1) {
        this.mapDevices.splice(idx, 1, {
          ...this.mapDevices[idx],
          direction: +direction,
          position: {
            lat: latitude,
            lon: longitude
          }
        })
      }
      if (this.deviceFollow?.deviceId === sensor.deviceId) {
        this.deviceFollow = {
          ...this.deviceFollow,
          direction: +direction,
          position: {
            lat: latitude,
            lon: longitude
          }
        }
      }
    }
    this.originDevices = [...this.devices]
  }

  @Mutation
  public destroyDevice(id: string): void {
    this.devices = this.devices.filter(d => d._id !== id)
    this.mapDevices = this.mapDevices.filter(d => d.id !== id)
    this.originDevices = [...this.devices]
  }

  @Mutation
  public toggleMapDevice(device: MapDeviceItem): void {
    const idx = this.mapDevices.findIndex(d => d.id === device.id)
    if (idx !== -1) {
      this.mapDevices.splice(idx, 1)
    } else {
      this.mapDevices.push(device)
    }
  }

  @Mutation
  public toggleAllMapDevice(stage: Stage): void {
    if (stage === 'fill') {
      this.mapDevices = this.devices.filter(d => d.sensors[0].lastValue).map(d => {
        const { direction, latitude, longitude } = JSON.parse(d.sensors[0].lastValue)

        return {
          id: d._id,
          deviceId: +d.deviceId,
          positionSensorId: d.sensors[0]._id,
          name: d.name,
          icon: L.icon({
            iconUrl: require(`@/assets/mapIcons/${d.icon}`),
            iconSize: [35, 35]
          }),
          direction,
          position: {
            lat: latitude,
            lon: longitude
          }
        }
      })
    } else {
      this.mapDevices = []
    }
  }

  @Mutation
  public setMapCenter(center: [number, number]): void {
    this.mapCenter = center
  }
}

export default LocationStore

export type Stage = 'fill' | 'clear'

export interface MapDeviceItem {
  id: string
  deviceId: number
  positionSensorId: string
  name: string
  icon: L.Icon
  direction: number
  position: Position
}

interface Position {
  lat: number;
  lon: number;
}

export interface UpdateDeviceSensor {
  deviceId: string
  sensorId: string
  sensorName: string
  lastValue: string
  lastUpdateTime: number
}

export interface FollowDeviceItem {
  deviceId: string
  name: string
  icon: string
  direction: number
  position: Position
}
