import { Component, Vue, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { TriggeredNotificationService } from '@/App/Services/TriggeredNotification.service'
import { Notification, TriggeredNotification } from '@/store/interfaces/shared.interfaces'
import { DataOptions } from 'vuetify'

const sharedStore = namespace('Shared')

@Component
export default class NotificationsHistoryTable extends Vue {
  @sharedStore.Mutation
  private readonly setNotification: (notification: Notification) => void

  public isLoading = false
  public search = ''
  public headers = [
    {
      text: this.$t('triggeredNotificationsDialog.notificationsHistoryTable.tableColTextText').toString(),
      align: 'left',
      sortable: false,
      value: 'text'
    },
    {
      text: this.$t('triggeredNotificationsDialog.notificationsHistoryTable.tableColCreatedAtText').toString(),
      align: 'left',
      sortable: true,
      value: 'dtCreate'
    },
    {
      text: this.$t('triggeredNotificationsDialog.notificationsHistoryTable.tableColViewedAtText').toString(),
      align: 'left',
      sortable: true,
      value: 'dtViewed'
    }
  ]
  public itemsPerPage = [ 5, 10, 15 ]
  public notificationsItems: TriggeredNotification[] = []
  public tableOptions: TableOptions = {
    search: '',
    page: 1,
    limit: 5,
    totalRows: 0
  }
  public debounce: undefined | number = undefined

  public onTableOptionsUpdated({ page, itemsPerPage, sortBy, sortDesc }: DataOptions): void {
    this.tableOptions.page = page
    this.tableOptions.limit = itemsPerPage

    if (sortBy[0]) {
      this.tableOptions.sortBy = sortBy[0]
      this.tableOptions.sortDesc = sortDesc[0]
    } else {
      delete this.tableOptions.sortBy
      delete this.tableOptions.sortDesc
    }

    this.fetchNotifications()
  }

  public async fetchNotifications(): Promise<void> {
    try {
      this.isLoading = true

      const { items, totalRows, limit, page } = await TriggeredNotificationService.fetch({
        isViewed: true,
        ...this.tableOptions
      })

      this.notificationsItems = items
      this.tableOptions = {
        ...this.tableOptions,
        page,
        limit,
        totalRows
      }
    } catch {} finally {
      this.isLoading = false
    }
  }

  @Watch('search')
  private searchChanged(text: string): void {
    if (text.length >= 3 || text.length === 0) {
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.tableOptions.search = text
        this.fetchNotifications()
      }, 700)
    }
  }
}

interface TableOptions {
  search: string
  page: number
  limit: number
  totalRows: number
  sortBy?: string
  sortDesc?: boolean
}
