import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import multiGuard from 'vue-router-multiguard'
import { roleAccess } from '@/router/Guards/RoleAccess.guard'
import { isAuth } from '@/router/Guards/IsAuth'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'mainLayout',
    component: () => import('@/App/Layouts/MainLayout/Main-Layout.vue'),
    redirect: '/users',
    children: [
      {
        path: '/organizations',
        component: () => import('@/App/Pages/Organization/Organizations-Page.vue'),
        beforeEnter: multiGuard([isAuth, roleAccess])
      },
      {
        path: '/users',
        component: () => import('@/App/Pages/Users/Users-Page.vue'),
        beforeEnter: multiGuard([isAuth, roleAccess])
      },
      {
        path: '/settings',
        component: () => import('@/App/Pages/Settings/Settings-Page.vue'),
        beforeEnter: multiGuard([isAuth, roleAccess])
      },
      {
        path: '/dashboards',
        component: () => import('@/App/Pages/Dashboard/Dashboards-Page.vue'),
        beforeEnter: isAuth,
        meta: { isSearchBarVisible: true }
      },
      {
        path: '/dashboards-templates',
        component: () => import('@/App/Pages/DashboardTemplates/Dashboard-Templates-Page.vue'),
        beforeEnter: isAuth
      },
      {
        path: '/service-intervals',
        component: () => import('@/App/Pages/ServiceIntervals/Service-Intervals-Page.vue'),
        beforeEnter: isAuth
      },
      {
        path: '/dashboards/:id/widgets',
        component: () => import('@/App/Pages/Widgets/Widgets-Page.vue'),
        beforeEnter: isAuth
      },
      {
        path: '/notifications',
        component: () => import('@/App/Pages/Notifications/Notifications-Page.vue'),
        beforeEnter: isAuth
      },
      {
        path: '/reports',
        component: () => import('@/App/Pages/Reports/Reports-Page.vue'),
        beforeEnter: isAuth
      },
      {
        path: '/scheduled-reports',
        component: () => import('@/App/Pages/ScheduleReports/Schedule-Reports-Page.vue'),
        beforeEnter: isAuth
      },
      {
        path: '/users-logs',
        component: () => import('@/App/Pages/UserLogs/Users-Logs-Page.vue'),
        beforeEnter: isAuth
      },
      {
        path: '/locations',
        component: () => import('@/App/Pages/Location/Locations-Page.vue'),
        beforeEnter: isAuth
      },
      {
        path: '/geofences',
        component: () => import('@/App/Pages/Geofence/Geofence-Page.vue'),
        beforeEnter: isAuth
      },
      {
        path: '/device-management',
        component: () => import('@/App/Pages/DeviceManagement/Device-Management-Page.vue'),
        beforeEnter: multiGuard([isAuth, roleAccess])
      },
      {
        path: '/device/:id/telemetry',
        component: () => import('@/App/Pages/DeviceTelemetry/Device-Telemetry-Page.vue'),
        beforeEnter: multiGuard([isAuth, roleAccess])
      },
      {
        path: '/organization-summary',
        component: () => import('@/App/Pages/OrganizationSummary/Organization-Summary-Page.vue'),
        beforeEnter: multiGuard([isAuth, roleAccess])
      },
      {
        path: '/sms-logs',
        component: () => import('@/App/Pages/SMSLogs/SMS-Logs-Page.vue'),
        beforeEnter: multiGuard([isAuth, roleAccess])
      },
      {
        path: '/administrator',
        component: () => import('@/App/Pages/Admin/Admin-Page.vue'),
        beforeEnter: multiGuard([isAuth, roleAccess]),
        redirect: 'administrator/users-online',
        children: [
          {
            path: 'users-online',
            component: () => import('@/App/Pages/Admin/UsersOnlinePage/Users-Online-Page.vue'),
            beforeEnter: multiGuard([isAuth, roleAccess])
          },
          {
            path: 'scheduled-reports',
            component: () => import('@/App/Pages/Admin/ScheduleReportsPage/Schedule-Reports-Page.vue'),
            beforeEnter: multiGuard([isAuth, roleAccess])
          },
          {
            path: 'notifications',
            component: () => import('@/App/Pages/Admin/NotificationsPage/Notifications-Page.vue'),
            beforeEnter: multiGuard([isAuth, roleAccess])
          },
          {
            path: 'smtp-logs',
            component: () => import('@/App/Pages/Admin/SMTPLogsPage/SMTP-Logs-Page.vue'),
            beforeEnter: multiGuard([isAuth, roleAccess])
          }
        ]
      }
    ]
  },
  {
    path: '/login',
    component: () => import('@/App/Layouts/Login-Layout.vue'),
    children: [
      {
        path: '/',
        component: () => import('@/App/Pages/Login/Login-Page.vue')
      },
      {
        path: 'password-reset',
        component: () => import('@/App/Pages/ResetPassword/Reset-Password-Page.vue')
      },
      {
        path: 'confirm-password-reset',
        component: () => import('@/App/Pages/ConfirmResetPassword/Confirm-Reset-Password-Page.vue')
      },
      {
        path: 'email-confirm',
        component: () => import('@/App/Pages/ConfirmUserEmail/Confirm-User-Email-Page.vue')
      }
    ]
  }
]

const router = new VueRouter({
  routes
})

export default router
