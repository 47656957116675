import { VuexModule, Module, Mutation } from 'vuex-module-decorators'
import { DeviceResponse } from '@/App/Services/interfaces/device.interface'
import { Polyline, SelectedDevice, UpdateDeviceSensor } from '@/store/interfaces/device.interfaces'

@Module({ namespaced: true, name: 'Device' })
class Device extends VuexModule {
  public devices: DeviceResponse[] = []
  public editingDevice: DeviceResponse = {} as DeviceResponse
  public selectedDevices: SelectedDevice[] = []
  public polyline: Polyline = []

  @Mutation
  public setDevices(devices: DeviceResponse[]): void {
    this.devices = devices
  }

  @Mutation
  public addDevice(device: DeviceResponse): void {
    this.devices.push(device)
  }

  @Mutation
  public destroyDevice(_id: string): void {
    this.devices = this.devices.filter(d => d._id !== _id)
  }

  @Mutation
  public updateDevice(device: DeviceResponse): void {
    this.devices = this.devices.map(d => {
      if (d._id === device._id) {
        return device
      } else {
        return d
      }
    })
  }

  @Mutation
  public updateDeviceSensor(payload: UpdateDeviceSensor): void {
    const dIdx = this.devices.findIndex(d => d._id === payload.deviceId)
    const sIdx = this.devices[dIdx].sensors.findIndex(s => s._id === payload.sensorId)

    const device = this.devices[dIdx]
    device.sensors[sIdx] = {
      ...this.devices[dIdx].sensors[sIdx],
      lastValue: payload.lastValue,
      lastUpdateTime: payload.lastUpdateTime
    }

    this.devices.splice(dIdx, 1, device)

    if (payload.sensorName === 'position') {
      const idx = this.selectedDevices.findIndex(d => d.deviceId === payload.deviceId && d.sensorId === payload.sensorId)

      if (idx !== -1) {
        const { latitude, longitude, direction } = JSON.parse(payload.lastValue)
        this.selectedDevices[idx].center = [latitude, longitude]
        this.selectedDevices[idx].direction = +direction
      }
    }
  }

  @Mutation
  public editDevice(device: DeviceResponse): void {
    this.editingDevice = device
  }

  @Mutation
  public toggleSelectedDevice(device: SelectedDevice): void {
    if (!this.selectedDevices.find(d => d.deviceId === device.deviceId)) {
      this.selectedDevices.push(device)
    } else {
      this.selectedDevices = this.selectedDevices.filter(d => d.deviceId !== device.deviceId)
    }
  }

  @Mutation
  public clearSelectedDevice(): void {
    this.selectedDevices = []
  }

  @Mutation
  public setPolyline(polyline: []): void {
    this.polyline = polyline
  }
}

export default Device
