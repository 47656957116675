import { Component, Mixins } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { SharedService } from '@/App/Services/Shared.service'
import FileUpload from './FileUpload/File-Uploader.vue'
import { Validator } from '@/App/Mixins'
import { SupportRequest } from '@/App/Services/interfaces/shared.interface'
import { Notification } from '@/store/interfaces/shared.interfaces'

const sharedStore = namespace('Shared')

@Component({
  components: { FileUpload }
})
export default class SupportForm extends Mixins(Validator) {
  $refs!: {
    form: HTMLFormElement,
    fileUpload: HTMLFormElement
  }

  @sharedStore.Mutation
  private readonly setNotification: (notification: Notification) => void

  public isOpen = false
  public isLoading = false
  public form = true
  public subjectItems = [ 'Affected Sensor', 'Device name' ]
  public request: SupportRequest = {
    subject: 'Affected Sensor',
    description: '',
    files: []
  }

  public open(): void {
    this.isOpen = true
  }

  public close(): void {
    this.request = {
      subject: 'Affected Sensor',
      description: '',
      files: []
    }

    this.$refs.form.resetValidation()
    this.$refs.fileUpload.clear()

    this.isOpen = false
  }

  public async submit(): Promise<void> {
    try {
      this.isLoading = true

      await SharedService.supportRequest(this.request)

      this.setNotification({ text: 'Your request was sent.' })

      this.close()
    } catch (e) {
      this.setNotification({ text: 'Error while sending request, try later.', color: 'error' })
    } finally {
      this.isLoading = false
    }
  }
}
