import Vue from 'vue'
import i18n from '@/plugins/i18n'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import './plugins/vue-swatches'
import './plugins/vue-leaflet'
import './plugins/vue-mask'
import './plugins/vue-json-pretty'
import './plugins/vue-resize-observer'
import './assets/styles.scss'
import './filters/'
import './plugins/vue-papa-parse/index'

Vue.config.productionTip = false

new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
