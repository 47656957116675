import { FileHTTP, HTTP } from '@/App/Utils/axios'
import { SupportRequest, OrganizationSummary, SMSLog } from '@/App/Services/interfaces/shared.interface'

export class SharedService {
	static async supportRequest({ subject, description, files }: SupportRequest): Promise<void> {
		try {
			const formData = new FormData()
			formData.append('subject', subject)
			formData.append('description', description)
			files.forEach(f => {
				formData.append('files', f)
			})

			await FileHTTP.post('/shared/support-request', formData)
		} catch (e) {
			throw e.response
		}
	}

	static async fetchAccountSummary(organizationId: string): Promise<OrganizationSummary> {
		try {
			const { data } = await HTTP.get(`shared/account-summary/${organizationId}`)

			return data
		} catch (e) {
			throw e.response
		}
	}

	static async toExcel(sheetName: string, info: string[]): Promise<void> {
		try {
			const { data } = await HTTP.get(`/shared/export-to-excel`, {
				responseType: 'blob',
				params: { sheetName, data: info }
			})

			const fileURL = window.URL.createObjectURL(new Blob([data]))
			const fileLink = document.createElement('a')

			fileLink.href = fileURL
			fileLink.setAttribute('download', `${sheetName}.xlsx`)
			document.body.appendChild(fileLink)
			fileLink.click()
			fileLink.remove()
		} catch (e) {
			throw e
		}
	}

	static async fetchSMSLogs(from: number, to: number, organizationId?: string): Promise<SMSLog[]> {
		try {
			const { data } = await HTTP.get(`shared/sms-logs`, { params: { from, to, organizationId } })

			return data
		} catch (e) {
			throw e.response
		}
	}
}
