import { Component, Vue } from 'vue-property-decorator'
import i18n from '@/plugins/i18n'
import isValEmpty from 'validator/lib/isEmpty'
import isValEmail from 'validator/lib/isEmail'
import isValURI from 'validator/lib/isURL'

@Component
export default class Validator extends Vue{
  static isEmpty(val: string | number, text = i18n.tc('validator.isEmpty'), isTextNeeded = true) {
    if (typeof val === 'number') {
      val = val.toString()
    }

    return !isValEmpty(val) || (isTextNeeded ? text : false)
  }

  static isEmail(val: string, text = i18n.tc('validator.isEmail'), isTextNeeded = true) {
    return isValEmail(val) || (isTextNeeded ? text : false)
  }

  static lessThan({ val, val2, text = `${i18n.tc('validator.lessThan')} ${val2}`, isTextNeeded = true }: LessThan) {
    return val <= val2 || (isTextNeeded ? text : false)
  }

  static isURI(val: string, text = i18n.tc('validator.isURI'), isTextNeeded = true) {
    return isValURI(val) || (isTextNeeded ? text : false)
  }
}

interface LessThan {
  val: number
  val2: number
  text?: string
  isTextNeeded?: boolean
}
