import { VuexModule, Module, Mutation } from 'vuex-module-decorators'
import { Device } from '@/App/Services/Flespi.service'

@Module({ namespaced: true, name: 'DeviceManagement' })
export default class DeviceManagement extends VuexModule {
  public devices: Device[] = []

  @Mutation
  public setDevices(devices: Device[]): void {
    this.devices = devices
  }

  @Mutation
  public addDevice(device: Device): void {
    this.devices.push(device)
  }

  @Mutation
  public destroyDevice(id: number): void {
    this.devices = this.devices.filter(d => d.id !== id)
  }

  @Mutation
  public updateDevice(device: Device): void {
    this.devices = this.devices.map(d => {
      if (d.id === device.id) {
        return device
      } else {
        return d
      }
    })
  }

  get findDeviceById() {
    return (id: number) => {
      return this.devices.find(d => d.id === id)
    }
  }
}
